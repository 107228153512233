@keyframes explodeup {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-50%)
    }
}

@keyframes explodedown {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(50%)
    }
}