.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    /* transition: opacity 700ms ease-in-out, visibility 100ms ease-in-out; */
}

.lightbox.open {
    opacity: 1;
    visibility: visible;
}

.lightbox-content {
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 333ms ease-in-out, transform 333ms ease-in-out;
}

.lightbox-content.open {
    opacity: 1;
    transform: scale(1);
    /* transition: opacity 100ms ease-in-out, transform 300ms ease-in-out; */
}

.lightbox-content.closing {
    opacity: 0;
    transform: scale(0.7);
}